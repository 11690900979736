export default class Contact {
    constructor(_container, _scroll) {
        this.container = document.querySelector(_container)
        this.scroll = _scroll
        if (!this.container) return false
        this.submitBtn = document.querySelector("#submit-form-btn")
        this.project_types_btns = document.querySelectorAll(".project-type")
        this.events()
    }

    events() {
            // requiered fields
        this.budget = document.querySelector("#budget")
        this.name = document.querySelector("#name")
        this.email = document.querySelector("#email")
        this.tel = document.querySelector("#tel")
        this.submitBtn.addEventListener("click", () => {
            if (!this.budget.value) {
                this.scrollTo(0, this.budget.offsetTop)
                this.budget.focus()
            } else if (!this.name.value) {
                this.scrollTo(0, this.name.offsetTop)
                this.name.focus()
            } else if (!this.email.value) {
                this.scrollTo(0, this.email.offsetTop)
                this.email.focus()
            } else if (!this.tel.value) {
                this.scrollTo(0, this.tel.offsetTop)
                this.tel.focus()
            } else {
                this.success()
            }
        })
        this.project_types_btns.forEach(item => this.projectTypeEvent(item))
    }

    scrollTo(x=0, y=0) {
        this.scroll.to({
            x: x,
            y: y
        })
    }

    success() {
        this.scrollTo(0,0)
        gsap.to("#send-success", .65, {
            delay: .35,
            ease: Bounce.easeOut,
            opacity: 1
        })
        gsap.to("#send-success", .35, {
            delay: .35,
            ease: Power2.easeOut,
            display: "block",
            onComplete: () => {
                this.scroll.scroll.updateMetrics()
            }
        })
        gsap.to("#send-success .success-item", .65, {
            ease: Bounce.easeOut,
            opacity: 1,
            delay: .35,
            stagger: 0.15
        })
        gsap.to("#contact-section .fade-item", .45, {
            ease: Circ.easeOut,
            opacity: 0,
            stagger: 0.015,
            display: "none"
        })
    }

    projectTypeEvent(_item) {
        _item.addEventListener("click", () => {
            let isActive = _item.getAttribute("class").indexOf("active") >= 0
            _item.blur()
            if (isActive) _item.classList.remove("active")
            else _item.classList.add("active")
        })
    }

}